<template>
  <div v-if="album.id">
    <div class="uk-box-shadow-medium uk-background-default uk-margin-top brd12 fs22@m fs18@s fnt-bld uk-padding-small">
      {{ album.name }}
    </div>
    <div class="uk-grid uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l">
      <router-link
        v-for="(video, i) in listVideos" :key="i"
        :to="`/video/${video.video_id}`"
        custom
        v-slot="{navigate}"
      >
      <div class="">
        <div
          @click="navigate"
          class="uk-box-shadow-medium uk-background-default uk-margin-top brd12 cr-pointer video-item uk-position-relative"
          >
            <div class="uk-grid uk-grid-small">
              <div class="video-item_prew uk-width-1-1">
                <img :src="`https://img.youtube.com/vi/${video.youtube_id}/0.jpg`" alt="">
              </div>
              <div class="uk-width-1-1 uk-text-left video-item_data">
                <div class="uk-padding-small">
                  <div class="fs18@m fs16@s fs14 fnt-med uk-height-xsmall@s">
                    {{ video.video_name }}
                  </div>

                  <div class="video-item_footer">
                    <div class="uk-grid uk-grid-small uk-margin-small uk-flex-between">
                      <div>
                        <div class="fs14@s fs12 cl-grey2">
                          г. {{ video.city }}
                        </div>
                      </div>
                      <div>
                        <div class="uk-margin-left fs14@s fs12 cl-grey2 uk-margin-right">
                          {{ dateString(video.date_event) }}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      </router-link>
    </div>
  </div>

</template>
<script>
import UIkit from 'uikit'

  export default {
    data() {
      return {
        id: '',
        album: {},
        listVideos: []
      }
    },

    created() {
      this.id = this.$route.params.id

      if (!this.id) return

      this.$api.post('admin/video/album/get', {
        id: this.id
      }).then(rs => {
        this.album = rs.data.item
      });

      this.$api.post('admin/video/album/get_videos', {
        id: this.id
      }).then(rs => {
        this.listVideos = rs.data.items
      });
    },

    methods: {

      dateString(value) {
        let dates = [
          'Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля',
          'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'
        ]

        let date = new Date(value)
        return `${date.getUTCDate()} ${dates[date.getUTCMonth()]} ${date.getUTCFullYear()} г.`
      },

      filterDate() {
        if (this.filter.date.length === 2) {
          if (this.filter.date[0] && this.filter.date[1]) {
            this.filterListVideos()
          }
        }
      },
    }
  }
</script>

<style scoped>
.video-item:hover {
  transition: 0.4s;
  opacity: 0.8
}


.video-item_prew img {
   border-top-left-radius:12px;
   border-top-right-radius:12px
}

@media (max-width: 960px) {
  .video-item_footer {
    position: static !important
  }
}

.video-item input[type="checkbox"] {
  border-radius: 6px;
  width: 30px;
  height: 30px;
  border: 1px solid #59bbb6
}

.video-item input[type="checkbox"]:not(:checked)  {
  background: #FFF
}
</style>
